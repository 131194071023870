import { Network, Wallet } from '@/schemas/bitcoin';
import { create } from 'zustand';

interface AccountState {
  isConnected: boolean;
  ordinalAddress: string;
  btcAddress: string;
  btcAddressPubKey: string;
  network: Network | '';
  isWebSocketActive: boolean;
  wallet: Wallet | undefined;
  setWallet: (wallet: Wallet | undefined) => void;
  setIsWebSocketActive: (isWebSocketActive: boolean) => void;
  setIsConnected: (isConnected: boolean) => void;
  setNetwork: (network: Network) => void;
  setWalletInfo: (info: { ordinalAddress: string; btcAddress: string; btcAddressPubKey: string; network: Network, wallet: Wallet }) => void;
  clearWalletInfo: () => void;
}

export const useAccountStore = create<AccountState>((set) => ({
  isConnected: false,
  ordinalAddress: '',
  btcAddress: '',
  btcAddressPubKey: '',
  network: '',
  isWebSocketActive: false,
  wallet: undefined,

  setIsWebSocketActive: (isWebSocketActive: boolean) => set({ isWebSocketActive }),
  setWallet: (wallet: 'leather' | 'xverse' | undefined) => set({ wallet }),
  setIsConnected: (isConnected: boolean) => set({ isConnected }),
  setNetwork: (network: Network) => {
    set({ network })
  },
  setWalletInfo: (info) => set({ ...info, isConnected: true }),
  clearWalletInfo: () => {
    set({isConnected: false});
    set({
      isConnected: false,
      ordinalAddress: '',
      btcAddress: '',
      btcAddressPubKey: '',
      wallet: undefined,
    })
  },
}));
