import { z } from "zod";
import { InscriptionIdSchema } from "./bitcoin";

export type KitIds = z.infer<typeof KitIdsSchema>;
export const KitIdsSchema = z.object({
    specialEditions: InscriptionIdSchema.array(),
    collection: InscriptionIdSchema.array()
});

export type KitDate = z.infer<typeof KitDateSchema>;
export const KitDateSchema = z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/)
    .refine((date) => {
        const d = new Date(date);
        return d >= new Date('2009-01-09');
    }, "Kit date must be on or after 2009-01-09");

export type KitImg = z.infer<typeof KitImgSchema>;
export const KitImgSchema = z.instanceof(Blob);

export type KitMetadata = z.infer<typeof KitMetadataSchema>;
export const KitMetadataSchema = z.any();

export type InscriptionData = z.infer<typeof InscriptionDataSchema>;
export const InscriptionDataSchema = z.object({
    address: z.string(),
});
