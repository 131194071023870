import type { Network } from "@/schemas/bitcoin";

type QueryKeySegment = string | number | boolean | null | undefined | Record<string, any>;

export const getEnvVar = (context: any, envVar: string) => {
    return (typeof context.cloudflare !== 'undefined')
        ? context.cloudflare.env[envVar]
        : process.env[envVar];
}

export const agoraUrl = (queryKey: QueryKeySegment[], network: Network): string => {
    const pathSegments: string[] = [];
    const searchParams: Record<string, string> = {};

    queryKey.forEach((segment) => {
        if (typeof segment === 'object' && segment !== null) {
            Object.entries(segment).forEach(([key, value]) => {
                if (value != null) {
                    searchParams[key] = String(value);
                }
            });
        } else if (segment != null) {
            pathSegments.push(String(segment).replace(/^\/+|\/+$/g, ''));
        }
    });

    const searchParamsString = new URLSearchParams(searchParams).toString();
    const path = pathSegments.join('/');

    let baseUrl;

    if (network === 'mainnet') {
        baseUrl = 'https://api.ephemera.gallery';
    } else if (network === 'signet') {
        baseUrl = 'https://api.signet.ephemera.gallery';
    } else if (network === 'regtest') {
        baseUrl = 'http://localhost:42069';
    } else {
        baseUrl = 'https://api.ephemera.gallery';
    }

    return searchParamsString ? `${baseUrl}/${path}?${searchParamsString}` : `${baseUrl}/${path}`;
};
