import { z } from "zod";
import { AddressSchema, BlockHeightSchema, InscriptionIdSchema, PsbtSchema, SatoshiSchema } from "./bitcoin";
import { KitDateSchema } from "./kit";

const TimestampSchema = z.number().int();

export type Connected = z.infer<typeof ConnectedSchema>;
export const ConnectedSchema = z.object({
    connected: z.coerce.number().int().nonnegative()
});

export type AuctionCycle = z.infer<typeof AuctionCycleSchema>;
export const AuctionCycleSchema = z.coerce.number().int().nonnegative();

export const BidStatusSchema = z.enum(['active', 'date_taken', 'finalized', 'finalizing', 'invalid', 'refunded', 'refunding', 'rolled_over', 'winner']);

export type Bid = z.infer<typeof BidSchema>;
export const BidSchema = z.object({
    amount: SatoshiSchema,
    auction_cycle: AuctionCycleSchema,
    kit_date: KitDateSchema,
    uuid: z.string().uuid(),
    ordinal_address: AddressSchema,
    status: BidStatusSchema,
    created: z.string().datetime(),
    psbt: PsbtSchema.optional().nullish(),
    inscription_id: InscriptionIdSchema.nullish(),
});

export type BidRank = z.infer<typeof BidRankSchema>;
export const BidRankSchema = z.tuple([z.number().int().nonnegative(), z.number().int().nonnegative()]);

export type RankedBid = Bid & { rank: BidRank };
export const RankedBidSchema: z.ZodType<RankedBid> = BidSchema.merge(z.object({
    rank: BidRankSchema,
}));

export type KitDateBidSummary = z.infer<typeof KitDateBidSummarySchema>;
export const KitDateBidSummarySchema = z.object({
    total_bids: z.number().int(),
    top_bid: RankedBidSchema,
    inscription_id: InscriptionIdSchema.nullish(),
});

export type AuctionDetails = z.infer<typeof AuctionDetailsSchema>;
export const AuctionDetailsSchema = z.object({
    auction_cycle: AuctionCycleSchema,
    bidding_address: AddressSchema,
    end_block: BlockHeightSchema,
    start_block: BlockHeightSchema,
    end_block_time: TimestampSchema,
});