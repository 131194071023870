import { z } from 'zod';

export type AddressPubKey = z.infer<typeof AddressPubKeySchema>
export const AddressPubKeySchema = z.string();

export type Address = z.infer<typeof AddressSchema>
export const AddressSchema = z.string();

export type BlockHeight = z.infer<typeof BlockHeightSchema>
export const BlockHeightSchema = z.number().int().nonnegative();

export type BlockInfo = z.infer<typeof BlockInfoSchema>
export const BlockInfoSchema = z.object({
    hash: z.string(),
    confirmations: z.number(),
    height: z.number(),
    time: z.number(),
    difficulty: z.number(),
    n_tx: z.number(),
});

export type BtcAddress = z.infer<typeof BtcAddressSchema>
export const BtcAddressSchema = AddressSchema;

export type BtcMessage = z.infer<typeof BtcMessageSchema>
export const BtcMessageSchema = z.string();

export type Btc = z.infer<typeof BtcSchema>
export const BtcSchema = z.number().nonnegative().lte(10_000);

export type InscriptionId = z.infer<typeof InscriptionIdSchema>
export const InscriptionIdSchema = z.string();

export type OrdinalAddress = z.infer<typeof OrdinalAddressSchema>
export const OrdinalAddressSchema = AddressSchema;

export type Psbt = z.infer<typeof PsbtSchema>
export const PsbtSchema = z.string();

export type Satoshi = z.infer<typeof SatoshiSchema>
export const SatoshiSchema = z.number().int().nonnegative();

export type ScriptPubKey = z.infer<typeof ScriptPubKeySchema>
export const ScriptPubKeySchema = z.string();

export type SigHash = z.infer<typeof SigHashSchema>
export const SigHashSchema = z.number().int();

export type Signature = z.infer<typeof SignatureSchema>
export const SignatureSchema = z.string();

export type SignInputs = z.infer<typeof SignInputsSchema>
export const SignInputsSchema = z.array(z.number().int());

export type Txid = z.infer<typeof TxidSchema>
export const TxidSchema = z.string();

export type UtxoIndex = z.infer<typeof UtxoIndexSchema>
export const UtxoIndexSchema = z.number().int().nonnegative();

export type FeeRate = z.infer<typeof FeeRateSchema>
export const FeeRateSchema = SatoshiSchema.lt(1_000);

export type Network = z.infer<typeof NetworkSchema>
export const NetworkSchema = z.enum(['mainnet', 'signet', 'regtest']);

export type NetworkAddress = MainnetAddress | SignetAddress | RegtestAddress;

export type Wallet = z.infer<typeof WalletSchema>
export const WalletSchema = z.enum(['leather', 'xverse']);

export const makeNetworkAddressSchema = ((network: Network) => {
    if (!NetworkSchema.safeParse(network).success) {
        throw new Error(`Invalid network: ${network}`);
    }
    const prefixes: Record<Network, string[]> = {
        mainnet: ['1', '3', 'bc1'],
        signet: ['sb', 'tb1'],
        regtest: ['m', '2', 'n', 'bcrt']
    };
    return AddressSchema.refine(
        address => prefixes[network].some(prefix => address.startsWith(prefix)),
        { message: `Invalid ${network} address` }
    );
});

export type MainnetAddress = z.infer<typeof MainnetAddressSchema>
export const MainnetAddressSchema = makeNetworkAddressSchema('mainnet');

export type RegtestAddress = z.infer<typeof RegtestAddressSchema>
export const RegtestAddressSchema = makeNetworkAddressSchema('regtest');

export type SignetAddress = z.infer<typeof SignetAddressSchema>
export const SignetAddressSchema = makeNetworkAddressSchema('signet');
