import { QueryClient, useQueryClient, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { agoraUrl } from "@/lib/agoraUrl";
import { clientFetchAndValidate } from "@/lib/clientFetchAndValidate";
import { AuctionDetails, AuctionDetailsSchema, Bid, BidSchema, KitDateBidSummary, KitDateBidSummarySchema, RankedBid, RankedBidSchema } from "@/schemas/auction";
import { BlockHeight, BlockHeightSchema, Network } from "@/schemas/bitcoin";
import { Utxo, UtxoSchema } from "@/schemas/api";
import { KitDate, KitDateSchema } from "@/schemas/kit";
import { useAccountStore } from "@/stores/accountStore";
import { useEffect, useRef } from "react";
import { z } from "zod";

type ParsedBidMessage = { parsedBid: RankedBid, success: true, timestamp: number } | { parsedBid: null, success: false, timestamp: number }

type Nullable<T> = T | null;

export const useAgoraWebsocket = () => {
    const queryClient = useQueryClient();
    const network = useAccountStore(state => state.network);
    const auctionCycle = useCurrentAuctionQuery().data?.auction_cycle || '';
    const auctionBidsRef = useRef<RankedBid[] | null>(null);
    const websocketActiveRef = useRef(false);
    const websocketRef = useRef<WebSocket | null>(null);
    const auctionBids = useAuctionCycleBidsQuery(auctionCycle.toString(), network).data;
    const setIsWebSocketActive = useAccountStore(state => state.setIsWebSocketActive);

    useWebsocketQuery(network);

    useEffect(() => {
        if (auctionBids === undefined || auctionBids === null) { return; }
        auctionBidsRef.current = auctionBids;
    }, [auctionBids]);

    useEffect(() => {
        setIsWebSocketActive(websocketActiveRef.current);
    }, [websocketActiveRef.current, setIsWebSocketActive]);

    useEffect(() => {
        if (network === '' || websocketActiveRef.current) {
            return;
        }

        websocketActiveRef.current = true;
        let websocket: WebSocket | null = null;

        function connectWebSocket(network: Network) {
            websocket = new WebSocket(agoraUrl(['events'], network).replace('https', 'wss'));
            websocketRef.current = websocket;

            websocket.onopen = () => {
                console.log('WebSocket connected');
                queryClient.setQueryData(['activity'], []);
                setupHeartBeat(websocket!);
            };

            websocket.onclose = () => {
                console.log('WebSocket closed. Attempting to reconnect...');
                setTimeout(connectWebSocket, 3000);
            };

            websocket.onmessage = (event) => {
                const json = JSON.parse(event.data);
                const row = { timestamp: event.timeStamp, data: json };

                queryClient.setQueryData(['activity'], (oldData: any[] = []) => {
                    if (json.type === 'Connected') {
                        return oldData;
                    }

                    const newData = [row, ...oldData];

                    return newData.slice(0, 100);
                });

                switch (json.type) {
                    case 'Connected':
                        queryClient.setQueryData(['connectedUsers'], json.connected);
                        break;
                    case 'BlockInfo':
                        queryClient.setQueryData(['blockInfo'], json);
                        queryClient.setQueryData(['block', 'height'], json.height);
                        queryClient.invalidateQueries({ queryKey: ['block', 'height'] });
                        break;
                    case 'Bid':
                        queryClient.setQueryData(['bids'], (old: z.infer<typeof BidSchema>[] = []) => [...old, json]);
                        queryClient.setQueryData(['newBid'], () => json);
                        const auctionCycle = json.auction_cycle;
                        if (auctionBidsRef.current === null || auctionBidsRef.current === undefined) {
                            return;
                        }
                        const { parsedBid, success } = parseNewBidEvent(event);
                        if (!success) {
                            return;
                        }
                        updateAuctionCycleBidDates(parsedBid, auctionCycle, queryClient, auctionBidsRef.current);
                        updateAuctionCycleBids(parsedBid, auctionCycle, queryClient);
                        updateAuctionCycleKitDateBids(parsedBid, auctionCycle, queryClient);
                        queryClient.invalidateQueries({ queryKey: ['auction', auctionCycle] });
                        break;
                    case 'BidRanks':
                        break;
                }
            };
        }

        function setupHeartBeat(ws: WebSocket) {
            const pingInterval = setInterval(() => {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify({ type: 'Heartbeat' }));
                }
            }, 5000);

            return () => clearInterval(pingInterval);
        }

        connectWebSocket(network);

        const visibilityChangeHandler = () => {
            if (!document.hidden && websocket?.readyState !== WebSocket.OPEN) {
                connectWebSocket(network);
            }
        };

        document.addEventListener('visibilitychange', visibilityChangeHandler);

        return () => {
            document.removeEventListener('visibilitychange', visibilityChangeHandler);
            if (websocket) {
                websocket.close();
            }
        };


    }, [queryClient, network]);

    return websocketRef.current;
};

export const useWebsocketQuery = (
    network: Network | '',
    options: Omit<UseQueryOptions<Nullable<any>, Error>, 'queryKey' | 'queryFn'> = {},
) => {
    return useQuery<Nullable<any>, Error>({
        queryKey: ['activity'],
        queryFn: () => { return [] },
        enabled: network !== '',
        staleTime: Infinity,
        ...options,
    });
};

const parseNewBidEvent = (event: MessageEvent): ParsedBidMessage => {
    const data = JSON.parse(event.data)
    const parsed = RankedBidSchema.safeParse(data)
    if (parsed.success) {
        return { timestamp: event.timeStamp, parsedBid: parsed.data, success: true }
    }
    return { timestamp: event.timeStamp, parsedBid: null, success: false }
}

const updateAuctionCycleBids = (newBid: RankedBid, auctionCycle: string | number, queryClient: QueryClient) => {
    queryClient.setQueryData(['auction', auctionCycle.toString(), 'bids'], (oldData: RankedBid[] | undefined) => {
        if (oldData === undefined) { return [newBid] }
        return [...oldData.filter(bid => bid.ordinal_address !== newBid.ordinal_address), newBid]
    });
}

const updateAuctionCycleKitDateBids = (newBid: RankedBid, auctionCycle: string | number, queryClient: QueryClient) => {
    queryClient.setQueryData(['auction', auctionCycle.toString(), 'bids', { kit_date: newBid.kit_date }], (oldData: RankedBid[] | undefined) => {
        if (oldData === undefined) { return [newBid] }
        return [...oldData.filter(bid => bid.ordinal_address !== newBid.ordinal_address), newBid]
    });
}

const updateAuctionCycleBidDates = (newBid: RankedBid, auctionCycle: string | number, queryClient: QueryClient, oldBids: RankedBid[]) => {
    queryClient.setQueryData(['auction', auctionCycle.toString(), 'dates'], (oldData: KitDateBidSummary[] | undefined) => {
        if (oldData === undefined) { return [{ top_bid: newBid, total_bids: 1 }] }

        let newBidAdded = false;

        const filteredOldBids = oldBids.filter(bid => bid.ordinal_address !== newBid.ordinal_address);

        let updatedData = oldData.map((data) => {

            if (data.top_bid.kit_date === newBid.kit_date) {
                newBidAdded = true;
                return { top_bid: newBid, total_bids: data.total_bids }
            }

            if (data.top_bid.ordinal_address === newBid.ordinal_address) {
                const oldBidWithSameKitDate = filteredOldBids.sort(
                    (a, b) => a.amount - b.amount
                ).find(bid => bid.kit_date === data.top_bid.kit_date);
                if (oldBidWithSameKitDate) {
                    return { top_bid: oldBidWithSameKitDate, total_bids: data.total_bids }
                } else {
                    return null
                }
            }
            return data
        }).filter(x => x !== null) as KitDateBidSummary[]

        if (!newBidAdded) {
            updatedData.push({ top_bid: newBid, total_bids: 1 });
        }

        updatedData.sort((a, b) => a.top_bid.amount === b.top_bid.amount
            ? (a.top_bid.created > b.top_bid.created) ? 1 : -1
            : b.top_bid.amount - a.top_bid.amount
        );

        updatedData = updatedData.map((data, index) => {
            return {
                top_bid: {
                    ...data.top_bid,
                    rank: [index + 1, 1]
                },
                total_bids: data.total_bids
            }
        });

        return updatedData;
    });
};

export const fetchAuctionCycleBidDates = async (auctionCycle: string | null, network: Network | ''): Promise<KitDateBidSummary[]> => {
    if (auctionCycle == '' || network == '') { return [] }
    const res = await clientFetchAndValidate(
        agoraUrl(['auction', auctionCycle, 'dates'], network),
        KitDateBidSummarySchema.array(),
        { headers: { 'Content-Type': 'application/json' } }
    );
    return res;
}

export const fetchAuctionCycleBids = async (auctionCycle: string, network: Network | ''): Promise<RankedBid[]> => {
    if (auctionCycle == '' || network == '') { return [] }
    const res = await clientFetchAndValidate(
        agoraUrl(['auction', auctionCycle, 'bids'], network),
        RankedBidSchema.array(),
        { headers: { 'Content-Type': 'application/json' } }
    );
    return res;
}

export const fetchAuctionCycleKitDateBids = async (auctionCycle: string, kitDate: string, network: Network | ''): Promise<Bid[]> => {
    if (auctionCycle == '' || kitDate == '' || network == '') {
        return [];
    }

    const res = await clientFetchAndValidate(
        agoraUrl(['auction', auctionCycle, 'bids', { kit_date: kitDate }], network),
        BidSchema.array(),
        { headers: { 'Content-Type': 'application/json' } }
    );
    return res;
}

export const fetchAuctionList = async (network: Network | ''): Promise<AuctionDetails[]> => {
    if (network == '') { return [] }
    const res = await clientFetchAndValidate(
        agoraUrl(['auction', 'list'], network),
        AuctionDetailsSchema.array(),
        { headers: { 'Content-Type': 'application/json' } }
    );
    return res;
};

export const fetchBlockHeight = async (network: Network | ''): Promise<BlockHeight | null> => {
    if (network == '') { return null }
    const res = await clientFetchAndValidate(
        agoraUrl(['block', 'height'], network),
        BlockHeightSchema,
        { headers: { 'Content-Type': 'application/json' } }
    );
    return res;
}

export const fetchCurrentAuction = async (network: Network | ''): Promise<AuctionDetails | null> => {
    if (network == '') { return null }
    const res = await clientFetchAndValidate(
        agoraUrl(['auction', 'current'], network),
        AuctionDetailsSchema,
        { headers: { 'Content-Type': 'application/json' } }
    );
    return res;
};

export const fetchDatesTaken = async (network: Network | ''): Promise<KitDate[]> => {
    if (network == '') { return [] }
    const datesTaken = await clientFetchAndValidate(
        agoraUrl(['date', 'taken'], network),
        KitDateSchema.array(),
        { headers: { 'Content-Type': 'application/json' } }
    );
    return [...datesTaken, '2009-01-10', '2009-01-12', '2012-02-29'];
}

export const fetchBidByUuid = async (network: Network | '', uuid: string): Promise<Bid | null> => {
    if (network == '') { return null }
    const res = await clientFetchAndValidate(
        agoraUrl(['bid', uuid], network),
        BidSchema,
        { headers: { 'Content-Type': 'application/json' } }
    );
    return res;
}

export const useAuctionCycleBidDatesQuery = (
    auctionCycle: string,
    options: Omit<UseQueryOptions<Nullable<KitDateBidSummary[]>, Error>, 'queryKey' | 'queryFn'> = {},
) => {
    const network = useAccountStore(state => state.network);
    return useQuery<Nullable<KitDateBidSummary[]>, Error>({
        queryKey: ['auction', auctionCycle, 'dates'],
        queryFn: () => fetchAuctionCycleBidDates(auctionCycle, network),
        enabled: network !== '' && auctionCycle !== '',
        ...options,
    });
};

export const useAuctionCycleBidsQuery = (
    auctionCycle: string,
    network: Network | '',
    options: Omit<UseQueryOptions<Nullable<RankedBid[]>, Error>, 'queryKey' | 'queryFn'> = {},
) => {
    return useQuery<Nullable<RankedBid[]>, Error>({
        queryKey: ['auction', auctionCycle, 'bids'],
        queryFn: () => fetchAuctionCycleBids(auctionCycle, network),
        enabled: network !== '' && auctionCycle !== '',
        ...options,
    });
};

export const useAuctionCycleKitDateBidsQuery = (
    auctionCycle: string,
    kitDate: string,
    network: Network | '',
    options: Omit<UseQueryOptions<Nullable<Bid[]>, Error>, 'queryKey' | 'queryFn'> = {},
) => {
    return useQuery<Nullable<Bid[]>, Error>({
        queryKey: ['auction', auctionCycle, 'bids', { kit_date: kitDate }],
        queryFn: () => fetchAuctionCycleKitDateBids(auctionCycle, kitDate, network),
        enabled: auctionCycle !== '' && kitDate !== '' && network !== '',
        ...options,
    });
};

export const useAuctionListQuery = (
    network: Network | '',
    options: Omit<UseQueryOptions<Nullable<AuctionDetails[]>, Error>, 'queryKey' | 'queryFn'> = {},
) => {
    return useQuery<Nullable<AuctionDetails[]>, Error>({
        queryKey: ['auction', 'list'],
        queryFn: () => fetchAuctionList(network),
        enabled: network !== '',
        ...options,
    });
};

export const useBlockHeightQuery = (
    network: Network | '',
    options: Omit<UseQueryOptions<Nullable<BlockHeight>, Error>, 'queryKey' | 'queryFn'> = {},
) => {
    return useQuery<Nullable<BlockHeight>, Error>({
        queryKey: ['block', 'height'],
        queryFn: () => fetchBlockHeight(network),
        enabled: network !== '',
        ...options,
    });
};

export const useCurrentAuctionQuery = (
    options: Omit<UseQueryOptions<Nullable<AuctionDetails>, Error>, 'queryKey' | 'queryFn'> = {},
) => {
    const network = useAccountStore(state => state.network);
    return useQuery<Nullable<AuctionDetails>, Error>({
        queryKey: ['auction', 'current'],
        queryFn: () => fetchCurrentAuction(network),
        enabled: network !== '',
        ...options,
    });
};

export const useBidByUuidQuery = (
    network: Network | '',
    uuid: string,
    options: Omit<UseQueryOptions<Nullable<Bid>, Error>, 'queryKey' | 'queryFn'> = {},
) => {
    return useQuery<Nullable<Bid>, Error>({
        queryKey: ['bid', uuid],
        queryFn: () => fetchBidByUuid(network, uuid),
        enabled: network !== '' && uuid !== '',
        ...options,
    });
};

export const useDateTakenQuery = (
    network: Network | '',
    options: Omit<UseQueryOptions<Nullable<KitDate[]>, Error>, 'queryKey' | 'queryFn'> = {},
) => {
    return useQuery<Nullable<KitDate[]>, Error>({
        queryKey: ['date', 'taken'],
        queryFn: () => fetchDatesTaken(network),
        enabled: network !== '',
        ...options,
    });
};

export const useBiddingUtxosQuery = (
    options: Omit<UseQueryOptions<Utxo[], Error>, 'queryKey' | 'queryFn'> = {},
) => {
    const network = useAccountStore(state => state.network) as Network;
    return useQuery<Utxo[], Error>({
        queryKey: ['utxos', 'bidding'],
        queryFn: async () => {
            const utxos = await clientFetchAndValidate(
                agoraUrl(['utxos', 'bidding'], network),
                UtxoSchema.array(),
                {
                    headers: { 'Content-Type': 'application/json' },
                },
            );
            return utxos;
        },
        ...options,
    });
};

export const calculateTimeRemaining = (endBlockTime: number): number => {
    const now = Math.floor(Date.now() / 1000);
    return Math.max(endBlockTime - now - 600, 0);
};
