import { z } from 'zod';
import { fromError } from 'zod-validation-error';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface FetchOptions extends Omit<RequestInit, 'method' | 'body'> {
  method?: HttpMethod;
  data?: unknown;
}

export class ClientFetchError extends Error {
  constructor(public status: number, message: string) {
    super(message);
    this.name = 'ClientFetchError';
  }
}

export async function clientFetchAndValidate<T extends z.ZodTypeAny>(
  url: string,
  schema: T,
  options: FetchOptions = {}
): Promise<z.infer<T>> {
  const { method = 'GET', data, ...fetchOptions } = options;
 
  const response = await fetch(url, {
    method,
    ...fetchOptions,
    headers: {
      'Content-Type': 'application/json',
      ...fetchOptions.headers,
    },
    body: data ? JSON.stringify(data) : undefined,
    credentials: 'include', // This line is important for CORS with credentials
  });

  if (!response.ok) {
    throw new ClientFetchError(response.status, response.statusText);
  }

  const responseData = await response.json();

  try {
    return schema.parse(responseData);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error('Validation error:', responseData,  fromError(error));
      throw new ClientFetchError(422, 'Invalid response data');
    }
    throw error;
  }
}
